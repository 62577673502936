import { Component, Grid, View } from 'outpost';
import { Building } from './building.ts';
import { GameScene } from '../scene.ts';
import { GameRoom } from '../game-room.ts';
import { BuildingKind } from './building-kind.ts';
import { Player } from '../player.ts';
import { getTileCenter } from '../map/map-utils.ts';

export class BuildingManager implements Component {
    game: GameRoom;
    buildingGrid: Grid<Building | null>;
    buildings: Building[] = [];

    constructor(game: GameRoom) {
        this.game = game;
        this.buildingGrid = new Grid({
            width: game.map.tiles.width,
            height: game.map.tiles.height,
            makeItem: () => null,
        });
    }

    update(elapsedSecs: number) {
        for (let building of this.buildings) {
            building.update(elapsedSecs);
        }
    }

    getBuildingAt(gridX: number, gridY: number): Building | null {
        return this.buildingGrid.get(gridX, gridY) ?? null;
    }

    spawn(owner: Player, kind: BuildingKind, gridX: number, gridY: number) {
        let building = new Building(this.game, owner, kind, getTileCenter(gridX, gridY));

        this.buildings.push(building);
        this.buildings.sort((a,b) => a.position.y - b.position.y)
        this.buildingGrid.set(gridX, gridY, building);
    }

    render(view: View): void {
        view.paint({ sceneId: GameScene.Overworld });
        view.addChild(this.buildings);
    }
}

globalThis.ALL_FUNCTIONS.push(BuildingManager);