import {
    Color,
    ColorLike,
    Component,
    Constructor,
    HorizontalAlign,
    Point,
    PointLike,
    Rect,
    RoomApi,
    Vector,
    View,
    registerSerializableAsset,
} from 'outpost';
import { GameRoom } from '../game-room.ts';
import { TILE_SIZE, STYLES } from '../constants.ts';
import { TILE_TO_MOVEMENT, Tile, getSpawnTile } from '../map/map-tiles.ts';
import { GameScene } from '../scene.ts';
import { BuildingElement } from '../buildings/building-types.ts';
import { WorldText } from '../world-text.ts';
import { Projectile } from '../projectiles/projectile.ts';
import { BuildingStats } from '../buildings/building-stats.ts';
import { paintTexts } from '../user-interface/user-interface.ts';
import { XP_GAINED_PER_KILL } from './creature-data.ts';

export type CreatureState = {
    name: string;
    health: number;
    speed: number;
    element: BuildingElement;

    size: number;
    imageUrl: string;
};

type Affliction = {
    stats: BuildingStats;
    tick: number;
    duration: number;
    tickDuration: number;
    damagePerTick: number;
    element: BuildingElement;
};

export class Creature implements Component {
    game: GameRoom;
    stats: CreatureState;
    damagesTaken: number = 0;
    lastDamageTakenTs: number = Number.NEGATIVE_INFINITY;
    position: Point;
    direction: Vector = Vector.zero();
    tooltipHideDelay = 5000;

    afflictions: Record<BuildingElement, Affliction | null> = {
        [BuildingElement.Fire]: null,
        [BuildingElement.Water]: null,
        [BuildingElement.Plant]: null,
    };

    constructor(game: GameRoom, baseStats: CreatureState, position: PointLike) {
        this.game = game;
        this.stats = structuredClone(baseStats);
        this.stats.health *= ((1 + 0.25) * game.level.level) | 0;
        this.position = Point.from(position);
        this.initDirection();
    }

    dealDamages(api: RoomApi, damages: number = 1, color = Color.white()) {
        this.damagesTaken += damages;
        this.lastDamageTakenTs = Date.now();

        if (color.getDistanceTo(Color.white()) > 0) {
            api.render(new WorldText(this.position, damages, color));
        }

        if (this.isDead()) {
            this.game.gainExp(XP_GAINED_PER_KILL / this.game.players.length);
            this.die();
        }
    }

    isDead(): boolean {
        return this.damagesTaken >= this.stats.health;
    }

    die() {
        for (let projectile of this.game.projectileManager.projectiles) {
            if (projectile.target === this) {
                this.game.projectileManager.projectiles.delete(projectile);
            }
        }
        this.game.creatureManager.creatures.delete(this);
    }

    initDirection() {
        let mapWidth = this.game.map.getWidth();
        let mapHeight = this.game.map.getHeight();
        let dLeft = this.position.x / mapWidth;
        let dRight = 1 - dLeft;
        let dTop = this.position.y / mapHeight;
        let dBottom = 1 - dTop;
        let min = Math.min(dLeft, dRight, dTop, dBottom);

        if (min === dLeft) {
            this.direction.x = 1;
        } else if (min === dRight) {
            this.direction.x = -1;
        } else if (min === dTop) {
            this.direction.y = 1;
        } else if (min === dBottom) {
            this.direction.y = -1;
        }
    }

    getTile() {
        const x = this.position.x;
        const y = this.position.y;

        return this.game.map.tiles.get(Math.floor(x / TILE_SIZE), Math.floor(y / TILE_SIZE));
    }

    updateDirection() {
        let tile = this.getTile();
        let movement = tile && TILE_TO_MOVEMENT[tile];

        if (!movement || (this.direction.x === movement.x && this.direction.y === movement.y)) {
            return;
        }

        let x = this.position.x;
        let y = this.position.y;

        let xInTilePercent = ((x + this.direction.x) % TILE_SIZE) / TILE_SIZE;
        let yInTilePercent = ((y + this.direction.y) % TILE_SIZE) / TILE_SIZE;

        if (
            (this.direction.x > 0 && xInTilePercent >= 0.5) ||
            (this.direction.x < 0 && xInTilePercent <= 0.5) ||
            (this.direction.y > 0 && yInTilePercent >= 0.5) ||
            (this.direction.y < 0 && yInTilePercent <= 0.5)
        ) {
            this.direction = movement ?? Vector.ZERO;
            this.position = Point.from({
                x: Math.floor(x / TILE_SIZE) * TILE_SIZE + TILE_SIZE / 2,
                y: Math.floor(y / TILE_SIZE) * TILE_SIZE + TILE_SIZE / 2,
            });
        }
    }

    updateCoreCollision() {
        const tile = this.getTile();

        if (tile === Tile.Core) {
            this.die();
            this.game.loseHealth();
        }
    }

    getElementTextColor(element: BuildingElement) {
        let textColor = Color.white();
        if (element === BuildingElement.Fire) {
            textColor = Color.from('#ff6655');
        } else if (element === BuildingElement.Water) {
            textColor = Color.royalBlue();
        } else if (element === BuildingElement.Plant) {
            textColor = Color.darkSeagreen();
        }

        return textColor;
    }

    applyElementalDamage(api: RoomApi, stats: BuildingStats, element: BuildingElement) {
        const damage1 = (stats.spikePercentDamages / 100) * this.stats.health;
        const damage2 = stats.burnDamages;
        const damage = (damage1 + damage2) | 0;

        this.dealDamages(api, damage, this.getElementTextColor(element));
    }

    applyElementEffect(api: RoomApi, projectile: Projectile) {
        let stats = structuredClone(projectile.owner.stats);

        if (stats.duration === 0) {
            this.applyElementalDamage(api, stats, projectile.owner.element);
            return;
        }

        const damage1 = (stats.spikePercentDamages / 100) * this.stats.health;
        const damage2 = stats.burnDamages;
        const damage = (damage1 + damage2) | 0;

        const affliction = {
            element: projectile.owner.element,
            stats: structuredClone(projectile.owner.stats),
            duration: stats.duration,
            tickDuration: stats.duration / damage,
            damagePerTick: 1,
            tick: 0,
        };

        this.afflictions[affliction.element] = affliction;
    }

    updateAffliction(api: RoomApi, elapsedSecs: number) {
        for (let afflictionOrNull of Object.values(this.afflictions)) {
            if (!afflictionOrNull) {
                continue;
            }
            const affliction = afflictionOrNull;
            affliction.duration -= elapsedSecs;

            if (affliction.duration <= 0) {
                this.afflictions[affliction.element] = null;
                continue;
            }

            affliction.tick += elapsedSecs;
            if (affliction.tick >= affliction.tickDuration) {
                affliction.tick -= affliction.tickDuration;
                this.dealDamages(api, affliction.damagePerTick, this.getElementTextColor(affliction.element));
            }
        }
    }

    getMovementSpeed() {
        let slowPercentage = Object.values(this.afflictions)
            .filter(Boolean)
            .reduce((percent, aff) => percent + aff!.stats.slowPercent / 100, 0);
        let speed = Math.max(0.1, this.stats.speed * (1 - slowPercentage));

        return speed;
    }

    updateMovement(api: RoomApi, elapsedSecs: number) {
        let speed = this.getMovementSpeed();
        this.position = this.position.add(this.direction.mult(elapsedSecs * speed));
    }

    update(api: RoomApi, elapsedSecs: number) {
        this.updateDirection();
        this.updateCoreCollision();
        this.updateAffliction(api, elapsedSecs);
        this.updateMovement(api, elapsedSecs);
    }

    tooltip(view: View): void {
        let rect = STYLES.layout.bottomRight;
        paintTexts(view, 'creature-tooltip', rect.strip(16), [
            {
                text: this.stats.name,
                size: 32,
                align: 'center',
                withDivider: true,
            },
            {
                text: `Health    : ${this.stats.health}`,
                size: 24,
            },
            {
                text: `Speed     : ${this.stats.speed}`,
                size: 24,
            },
            {
                text: `Element : ${BuildingElement[this.stats.element]}`,
                size: 24,
            },
        ]);
    }

    render(view: View): void {
        let isSlowed = Boolean(this.afflictions[BuildingElement.Water]);
        let isBurnt = Boolean(this.afflictions[BuildingElement.Fire]);
        let speed = this.getMovementSpeed();

        let currentHealth = this.stats.health - this.damagesTaken;
        let healthRect = Rect.from([this.stats.size, this.stats.size / 4])
            .withCenter(this.position.x, this.position.y)
            .translate(0, -this.stats.size * 0.5 - 5);

        if (currentHealth <= 0) {
            return;
        }

        if (isSlowed && isBurnt) {
            view.paint('water-effect', {
                sceneId: GameScene.OverworldUi,
                image: 'assets/effects/water_3x1.png',
                imageSprite: {
                    start: 0,
                    end: '100%',
                    duration: 3000,
                    loop: 'repeat',
                },
                width: TILE_SIZE,
                height: TILE_SIZE,
                position: this.position,
            });
            view.paint('fire-effect', {
                sceneId: GameScene.OverworldUi,
                image: 'assets/effects/fire_7x1.png',
                imageSprite: {
                    start: 0,
                    end: '100%',
                    duration: 3000,
                    loop: 'repeat',
                },
                width: TILE_SIZE,
                height: TILE_SIZE,
                position: this.position,
            });
        } else if (isSlowed) {
            view.paint('water-effect', {
                sceneId: GameScene.OverworldUi,
                image: 'assets/effects/water_3x1.png',
                imageSprite: {
                    start: 0,
                    end: '100%',
                    duration: 3000,
                    loop: 'repeat',
                },
                width: TILE_SIZE,
                height: TILE_SIZE,
                position: this.position,
            });
        } else if (isBurnt) {
            view.paint('fire-effect', {
                sceneId: GameScene.OverworldUi,
                image: 'assets/effects/fire_7x1.png',
                imageSprite: {
                    start: 0,
                    end: '100%',
                    duration: 250,
                    loop: 'repeat',
                },
                width: TILE_SIZE,
                height: TILE_SIZE,
                position: this.position,
            });
        }

        const color = this.getElementTextColor(this.stats.element);

        view.paint('sprite', {
            tint: color,
            image: this.stats.imageUrl,
            imageSprite: {
                start: 0,
                end: '100%',
                duration: (TILE_SIZE / speed) * 500,
                loop: 'repeat',
            },
            width: TILE_SIZE,
            height: TILE_SIZE,
            position: this.position,
        });

        if (this.damagesTaken > 0) {
            view.paint('health-bg', {
                color: 'red',
                rect: healthRect,
            });

            view.paint('health', {
                sceneId: GameScene.OverworldUi,
                color: 'chartreuse',
                rect: healthRect,
                fillPercentLinearDirection: 'left-to-right',
                fillPercentLinear: currentHealth / this.stats.health,
            });
        }
    }
}

export function makeCreature(stats: CreatureState) {
    let ctor = class extends Creature {
        constructor(game: GameRoom, position: PointLike) {
            super(game, stats, position);
        }
    };

    registerSerializableAsset(ctor);

    return ctor;
}

globalThis.ALL_FUNCTIONS.push(Creature);