import { Component, Point, PointLike, RoomApi, View, registerSerializableAsset } from 'outpost';
import { GameRoom } from '../game-room.ts';
import { TILE_SIZE } from '../constants.ts';
import { Creature } from '../creatures/creature.ts';
import {
    BuildingTargetMode,
    BuildingElement,
    ELEMENT_TO_COLOR,
    getDamageMultiplier,
} from '../buildings/building-types.ts';
import { Building } from '../buildings/building.ts';
import { ProjectileExplosion } from './projectile-explosion.ts';

export type ProjectileParams = {
    targetMode: BuildingTargetMode;
    element: BuildingElement;
};

export class Projectile implements Component {
    game: GameRoom;
    baseStats: ProjectileParams;
    position: Point;
    target: Creature;
    owner: Building;
    exploded: boolean = false;

    constructor(game: GameRoom, baseStats: ProjectileParams, owner: Building, target: Creature, position: Point) {
        this.game = game;
        this.baseStats = baseStats;
        this.position = position;
        this.owner = owner;
        this.target = target;
    }

    getTile(position: Point) {
        let x = position.x;
        let y = position.y;

        return this.game.map.tiles.get(Math.floor(x / TILE_SIZE), Math.floor(y / TILE_SIZE));
    }

    explode(api: RoomApi) {
        let damages = this.owner.stats.attackDamages;

        if (this.baseStats.targetMode === BuildingTargetMode.Single) {
            let multiplier = getDamageMultiplier(this.owner.element, this.target.stats.element);

            this.target.dealDamages(api, damages * multiplier);
            this.target.applyElementEffect(api, this);
        } else {
            for (let creature of this.game.creatureManager.creatures) {
                let distance = creature.position.getDistanceTo(this.position);
                let multiplier = getDamageMultiplier(this.owner.element, creature.stats.element);

                if (distance < (this.owner.stats.range / 2) * TILE_SIZE) {
                    creature.dealDamages(api, damages * multiplier);
                    creature.applyElementEffect(api, this);
                    api.render(new ProjectileExplosion(this.position, ELEMENT_TO_COLOR[this.baseStats.element]));
                }
            }
        }

        this.game.projectileManager.projectiles.delete(this);
    }

    update(api: RoomApi, elapsedSecs: number) {
        let direction = this.position.getVectorTo(this.target.position);
        let distance = direction.getLength();
        let stats = this.owner.stats;

        if (distance <= TILE_SIZE / 2) {
            this.explode(api);
        }

        this.position = this.position.add(direction.normalize().mult(elapsedSecs * stats.projectileSpeed * TILE_SIZE));
    }

    render(view: View): void {
        let stats = this.owner.stats;
        let color = ELEMENT_TO_COLOR[this.baseStats.element];

        view.paint('proj', {
            color,
            borderRadius: '50%',
            width: stats.projectileSize * TILE_SIZE,
            height: stats.projectileSize * TILE_SIZE,
            position: this.position,
        });
    }
}

export function makeProjectile(stats: ProjectileParams) {
    let ctor = class extends Projectile {
        constructor(game: GameRoom, owner: Building, target: Creature, position: PointLike) {
            super(game, stats, owner, target, Point.from(position));
        }
    };

    registerSerializableAsset(ctor);

    return ctor;
}

export const FireArrow = makeProjectile({
    element: BuildingElement.Fire,
    targetMode: BuildingTargetMode.Single,
});

export const FireBall = makeProjectile({
    element: BuildingElement.Fire,
    targetMode: BuildingTargetMode.Area,
});

export const WaterArrow = makeProjectile({
    element: BuildingElement.Water,
    targetMode: BuildingTargetMode.Single,
});

export const WaterBall = makeProjectile({
    element: BuildingElement.Water,
    targetMode: BuildingTargetMode.Area,
});

export const PlantArrow = makeProjectile({
    element: BuildingElement.Plant,
    targetMode: BuildingTargetMode.Single,
});

export const PlantBall = makeProjectile({
    element: BuildingElement.Plant,
    targetMode: BuildingTargetMode.Area,
});

globalThis.ALL_FUNCTIONS.push(Projectile);