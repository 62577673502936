import { RoomApi, getRandomItem, shuffleArray } from 'outpost';
import {
    BUILDING_ELEMENTS,
    BUILDING_TARGET_MODES,
    BuildingElement,
    BuildingTargetMode,
} from '../buildings/building-types.ts';
import { RewardStats } from './reward-types.ts';
import {
    FIXED_SPELL_UPGRADE_REWARDS,
    FIXED_TOWER_UPGRADE_REWARDS,
    MAX_TOWER_COUNT,
    MIN_TOWER_COUNT,
    SPELL_UPGRADE_PROBABILITY,
    TOWER_UPGRADE_PROBABILITY,
} from './reward-data.ts';

export function generateTowerCraftReward(api: RoomApi): RewardStats {
    let towerCount = (api.getRandomNumber() * (MAX_TOWER_COUNT - MIN_TOWER_COUNT + 1) + MIN_TOWER_COUNT) | 0;
    let elements = shuffleArray(BUILDING_ELEMENTS.slice());
    let elementCountToRemove = (api.getRandomNumber() * 3) | 0;

    for (let i = 0; i < elementCountToRemove; ++i) {
        elements.pop();
    }

    let towers: [BuildingElement, BuildingTargetMode][] = [];

    for (let i = 0; i < towerCount; ++i) {
        let element = getRandomItem(elements, () => api.getRandomNumber());
        let targetMode = getRandomItem(BUILDING_TARGET_MODES, () => api.getRandomNumber());

        towers.push([element, targetMode]);
    }

    return {
        kind: 'tower-craft',
        towersToCraftElements: elements,
        towersToCraft: towers,
    };
}

export function generateTowerUpgradeReward(api: RoomApi): RewardStats {
    let upgradedElement = getRandomItem(BUILDING_ELEMENTS, () => api.getRandomNumber());
    let fixedUpgrade: Omit<RewardStats, 'kind'> | null = null;

    while (!fixedUpgrade || ('upgradedElement' in fixedUpgrade && fixedUpgrade.upgradedElement !== upgradedElement)) {
        fixedUpgrade = getRandomItem(FIXED_TOWER_UPGRADE_REWARDS, () => api.getRandomNumber());
    }

    return {
        kind: 'tower-upgrade',
        upgradedElement,
        ...fixedUpgrade,
    };
}

export function generateSpellUpgradeReward(api: RoomApi): RewardStats {
    let fixedUpgrade = getRandomItem(FIXED_SPELL_UPGRADE_REWARDS, () => api.getRandomNumber());

    return {
        kind: 'spell-upgrade',
        ...fixedUpgrade,
    };
}

export function generateReward(api: RoomApi, index: number): RewardStats {
    if (index === 0) {
        return generateTowerCraftReward(api);
    }

    let p = api.getRandomNumber();

    if (p < TOWER_UPGRADE_PROBABILITY) {
        return generateTowerUpgradeReward(api);
    } else if (p < TOWER_UPGRADE_PROBABILITY + SPELL_UPGRADE_PROBABILITY) {
        return generateSpellUpgradeReward(api);
    } else {
        return generateTowerCraftReward(api);
    }
}
