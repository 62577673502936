import { ColorLike, Component, Point, Vector, View } from 'outpost';
import { GameScene } from './scene.ts';
import { TILE_SIZE } from './constants.ts';

export class WorldText implements Component {
    position: Point;
    value: number;
    color: ColorLike;

    constructor(position: Point, value: number, color: ColorLike) {
        this.position = position;
        this.value = value;
        this.color = color;
    }

    render(view: View): void {
        view.paint({
            duration: 500,
            sceneId: GameScene.OverworldUi,
            text: String(this.value),
            width: 100,
            height: 100,
            textSize: (4 * Math.log(this.value) + 24) | 0,
            position: {
                start: this.position,
                end: this.position.add([Math.random() * 3 - 1.5, -TILE_SIZE]),
                duration: 500,
            },
            textColor: this.color,
        });
    }
}

globalThis.ALL_FUNCTIONS.push(WorldText);