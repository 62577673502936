import { Component, Constructor, Counter, Point, PointLike, RoomApi, View, computeAttributesDetails } from 'outpost';
import { Creature } from './creature.ts';
import { GameScene } from '../scene.ts';
import { GameRoom } from '../game-room.ts';
import { Tile } from '../map/map-tiles.ts';

export class CreatureManager implements Component {
    game: GameRoom;
    creatures: Set<Creature> = new Set();
    spawnCounter: Counter = new Counter();

    constructor(game: GameRoom) {
        this.game = game;
    }

    update(api: RoomApi, elapsedSecs: number) {
        for (let creature of this.creatures) {
            creature.update(api, elapsedSecs);
        }
    }

    teleport(sourcePosition: Point, targetPosition: Point, diameter: number) {
        let movement = sourcePosition.getVectorTo(targetPosition);

        for (let creature of this.creatures) {
            if (creature.position.getDistanceTo(sourcePosition) < (creature.stats.size + diameter) / 2) {
                let newPosition = creature.position.add(movement);
                let { tile } = this.game.map.getTileAt(newPosition.x, newPosition.y);

                if (tile && tile >= Tile.Road) {
                    creature.position = newPosition;
                }
            }
        }
    }

    spawn(ctor: Constructor<Creature, [GameRoom, PointLike]>) {
        let location = this.game.map.getSpawnLocation(this.spawnCounter.next());
        let creature = new ctor(this.game, location);

        this.creatures.add(creature);
    }

    render(view: View): void {
        view.paint({ sceneId: GameScene.Overworld });
        view.addChild([...this.creatures].toSorted((a, b) => a.position.y - b.position.y));
    }
}

globalThis.ALL_FUNCTIONS.push(CreatureManager);