import { Component, DisplaySize, HorizontalAlign, Rect, RoomApi, View } from 'outpost';
import { GameRoom } from '../game-room.ts';
import { GameScene } from '../scene.ts';
import { CORE_HP, STYLES } from '../constants.ts';
import { Player } from '../player.ts';
import { Creature } from '../creatures/creature.ts';
import { getBuildingStats } from '../buildings/building-data.ts';
import { getBuildingElement, getBuildingTargetMode } from '../buildings/building-kind.ts';
import { BuildingElement, BuildingTargetMode } from '../buildings/building-types.ts';
import { Building } from '../buildings/building.ts';
import { BuildingStats } from '../buildings/building-stats.ts';

export class UserInterface implements Component {
    game: GameRoom;

    constructor(game: GameRoom) {
        this.game = game;
    }

    render(view: View): void {
        let player = view.getActiveClient(Player);

        view.paint({ sceneId: GameScene.UI });

        view.addChild(player.constructionPanel);
        paintPanel(view, 'building-panel', STYLES.layout.bottom.translate(0, 32), 'center');
        paintPanel(view, 'tooltip', STYLES.layout.bottomRight, 'right');
        paintPanel(view, 'spell', STYLES.layout.bottomLeft, 'left');

        let firstRowRect = STYLES.layout.bottomLeft.fromTopLeftInwards(100, 100).translate(24, 24);
        view.paint({
            key: 'ui-xp',
            text: `XP`,
            rect: firstRowRect,
            horizontalAlign: 'left',
            textSize: 42,
            textColor: STYLES.textColor,
            offsetY: 2,
        });

        let xpRect = firstRowRect.fromRightOutwards(100, '100%', 8).withHeight(24).withWidth(180);

        view.paint('xp-jauge-bg', {
            color: 'royalblue',
            rect: xpRect,
        });

        view.paint('xp-jauge', {
            color: 'aliceblue',
            rect: xpRect.strip(4),
            fillPercentLinearDirection: 'left-to-right',
            fillPercentLinear: this.game.level.currentExp / (this.game.level.totalRequiredExp - 1),
        });

        let levelRect = xpRect.fromRightOutwards(100, '100%', 8);

        view.paint({
            key: 'ui-level',
            text: `Lvl. ${this.game.level.level}`,
            rect: levelRect,
            horizontalAlign: 'left',
            verticalAlign: 'middle',
            textSize: 42,
            textColor: STYLES.textColor,
            offsetY: 2,
        });

        let secondRowRect = STYLES.layout.bottomLeft.fromTopLeftInwards(100, 100).translate(24, 24 + 42);
        view.paint({
            key: 'ui-hp',
            text: `HP`,
            rect: secondRowRect,
            horizontalAlign: 'left',
            textSize: 42,
            textColor: STYLES.textColor,
            offsetY: 2,
        });

        let hpRect = secondRowRect.fromRightOutwards(100, '100%', 8).withHeight(24).withWidth(180);

        view.paint('hp-jauge-bg', {
            color: 'lime',
            rect: hpRect,
        });

        view.paint('hp-jauge', {
            color: 'greenyellow',
            rect: hpRect.strip(4),
            fillPercentLinearDirection: 'left-to-right',
            fillPercentLinear: this.game.health / (CORE_HP - 1),
        });

        let hpRect2 = hpRect.fromRightOutwards(100, '100%', 8);

        view.paint({
            key: 'ui-hp2',
            text: `${this.game.health}/${CORE_HP}`,
            rect: hpRect2,
            horizontalAlign: 'left',
            verticalAlign: 'middle',
            textSize: 42,
            textColor: STYLES.textColor,
            offsetY: 2,
        });
    }
}

export function paintPanel(view: View, key: string, rect: Rect, align: 'left' | 'right' | 'center') {
    view.paint({
        key: `${key}-border`,
        sceneId: GameScene.UI,
        color: STYLES.borderColor,
        rect: rect.pad(16),
        borderRadius: 16,
    });

    let bgRect = rect;

    if (align === 'center') {
        /* Do nothing. */
    } else if (align === 'left') {
        bgRect = bgRect.translate(-8, 8);
    } else if (align === 'right') {
        bgRect = bgRect.translate(8, 8);
    }

    view.paint({
        key: `${key}-bg`,
        sceneId: GameScene.UI,
        color: STYLES.backgroundColor,
        rect: bgRect,
        borderRadius: 16,
    });
}

export function paintTexts(
    view: View,
    name: string,
    rect: Rect,
    texts: { text: string; size: number; withDivider?: boolean; align?: HorizontalAlign }[]
) {
    let heights: number[] = [];

    function addText(name: string, text: string, height: number, align?: HorizontalAlign) {
        view.paint({
            key: 'tooltip-' + name,
            sceneId: GameScene.UI,
            text,
            textColor: STYLES.textColor,
            textSize: height,
            horizontalAlign: align ?? 'left',
            rect: rect.fromTopInwards('100%', height).translate(
                0,
                heights.reduce((acc, h) => acc + h, 0)
            ),
        });
        heights.push(height);
    }

    for (let i = 0; i < texts.length; i++) {
        const text = texts[i];
        addText(name + '-text-' + i, text.text, text.size, text.align);
        if (text.withDivider) {
            view.paint({
                key: name + '-divider-' + i,
                sceneId: GameScene.UI,
                color: 'snow',
                borderRadius: 2,
                rect: rect
                    .fromTopInwards('100%', 4)
                    .translate(
                        0,
                        heights.reduce((acc, h) => acc + h, 0)
                    )
                    .withSize(200, 4),
            });
            heights.push(32);
        }
    }
}

export function paintBuildingTooltip(view: View, kind: number, stats: BuildingStats) {
    const targetMode = getBuildingTargetMode(kind);
    const element = getBuildingElement(kind);

    let name = '';
    if (targetMode === BuildingTargetMode.Area) {
        name = 'Canon tower';
    } else {
        name = 'Arrow tower';
    }
    name += ' of ' + BuildingElement[element];

    paintTexts(view, 'building-tooltip', STYLES.layout.bottomRight.strip(20), [
        {
            text: name,
            size: 32,
            align: 'center',
            withDivider: true,
        },
        {
            text: targetMode ? 'Deals damage to a single target.' : 'Deals damage in a area of effect.',
            size: 24,
        },
        {
            text: 'Damage : ' + stats.attackDamages + ' per hit',
            size: 24,
        },
        {
            text: 'Fire rate : ' + stats.attacksPerSecond + ' per sec.',
            size: 24,
        },
        {
            text: 'Fire range : ' + stats.range + ' tile',
            size: 24,
        },
    ]);
}

globalThis.ALL_FUNCTIONS.push(UserInterface);