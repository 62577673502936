import { Button, Component, PromiseWithResolvers, RoomApi, View, makePromise } from 'outpost';
import { GameScene } from '../scene.ts';

export class EndOfGamePanel implements Component {
    endWhen: PromiseWithResolvers = makePromise();
    button = new Button('Leave');

    constructor() {
        this.button.setStyle({ base: { background: { color: 'sienna' } } });
    }

    async $ok(api: RoomApi) {
        api.setPriority(1);
        await api.waitForItemSelection(this.button);
        this.endWhen.resolve();
    }

    render(view: View): void {
        let rect = view.rect.scale(0.8);
        let [_, buttonRect] = rect.split('top', ['75%']);

        view.paint({
            sceneId: GameScene.UI,
            rect,
            color: 'white',
            borderRadius: '10%',
        });

        view.paint({
            key: 'stroke',
            color: 'black',
            strokeSize: '2%',
        });

        view.paint({
            key: 'text',
            text: 'Game over',
            textSize: '20%',
        });

        buttonRect = buttonRect.scale(0.7).stripToMatchAspectRatio(4);

        view.addChild(this.button, buttonRect);
    }

    getResolvePromise(): Promise<void> {
        return this.endWhen.promise;
    }
}

globalThis.ALL_FUNCTIONS.push(EndOfGamePanel);