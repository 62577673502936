import { BuildingElement } from '../buildings/building-types.ts';
import { TILE_SIZE } from '../constants.ts';
import { makeCreature } from './creature.ts';

const SKULL_HP = 90;
const SLIME_HP = 140;
const BAT_HP = 60;
export const XP_GAINED_PER_KILL = 1;

export const FireSkullCreature = makeCreature({
    name: 'Fire Skull',
    health: SKULL_HP, // * 25 per level.
    speed: TILE_SIZE,
    element: BuildingElement.Fire,

    size: TILE_SIZE,
    imageUrl: 'assets/creatures/skull_2x1.png',
});

export const FireSlimeCreature = makeCreature({
    name: 'Fire Slime',
    health: SLIME_HP, // * 25 per level.
    speed: TILE_SIZE * 0.25,
    element: BuildingElement.Fire,

    size: TILE_SIZE,
    imageUrl: 'assets/creatures/slime_2x1.png',
});

export const FireBatCreature = makeCreature({
    name: 'Fire Bat',
    health: BAT_HP, // * 25 per level.
    speed: TILE_SIZE * 1.5,
    element: BuildingElement.Fire,

    size: TILE_SIZE,
    imageUrl: 'assets/creatures/bat_2x1.png',
});

export const WaterSkullCreature = makeCreature({
    name: 'Water Skull',
    health: SKULL_HP, // * 25 per level.
    speed: TILE_SIZE,
    element: BuildingElement.Water,

    size: TILE_SIZE,
    imageUrl: 'assets/creatures/skull_2x1.png',
});

export const WaterSlimeCreature = makeCreature({
    name: 'Water Slime',
    health: SLIME_HP, // * 25 per level.
    speed: TILE_SIZE * 0.25,
    element: BuildingElement.Water,

    size: TILE_SIZE,
    imageUrl: 'assets/creatures/slime_2x1.png',
});

export const WaterBatCreature = makeCreature({
    name: 'Water Bat',
    health: BAT_HP, // * 25 per level.
    speed: TILE_SIZE * 1.5,
    element: BuildingElement.Water,

    size: TILE_SIZE,
    imageUrl: 'assets/creatures/bat_2x1.png',
});

export const PlantSkullCreature = makeCreature({
    name: 'Plant Skull',
    health: SKULL_HP, // * 25 per level.
    speed: TILE_SIZE,
    element: BuildingElement.Plant,

    size: TILE_SIZE,
    imageUrl: 'assets/creatures/skull_2x1.png',
});

export const PlantSlimeCreature = makeCreature({
    name: 'Plant Slime',
    health: SLIME_HP, // * 25 per level.
    speed: TILE_SIZE * 0.25,
    element: BuildingElement.Plant,

    size: TILE_SIZE,
    imageUrl: 'assets/creatures/slime_2x1.png',
});

export const PlantBatCreature = makeCreature({
    name: 'Plant Bat',
    health: BAT_HP, // * 25 per level.
    speed: TILE_SIZE * 1.5,
    element: BuildingElement.Plant,

    size: TILE_SIZE,
    imageUrl: 'assets/creatures/bat_2x1.png',
});
