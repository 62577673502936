import { Point, Rect } from 'outpost';
import { TILE_SIZE } from '../constants.ts';

export function getTileCenter(gridX: number, gridY: number): Point {
    return Rect.resolve({
        x1: gridX * TILE_SIZE,
        y1: gridY * TILE_SIZE,
        width: TILE_SIZE,
        height: TILE_SIZE,
    }).getCenter();
}
