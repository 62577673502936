const BASE_REQUIRED_EXP = 20;

export class GameLevel {
    level: number;
    totalRequiredExp: number;
    currentExp: number = 0;

    constructor(level: number = 1, totalRequiredExp: number = BASE_REQUIRED_EXP) {
        this.level = level;
        this.totalRequiredExp = totalRequiredExp;
    }

    getNextLevel(): GameLevel {
        return new GameLevel(this.level + 1, Math.ceil(this.totalRequiredExp * 1.5));
    }

    gainExp(amount: number = 1): boolean {
        this.currentExp += amount;

        return this.currentExp >= this.totalRequiredExp;
    }
}

globalThis.ALL_FUNCTIONS.push(GameLevel);