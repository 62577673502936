import { Color, ColorLike, Component, Point, View } from 'outpost';
import { GameRoom } from '../game-room.ts';
import { TILE_SIZE } from '../constants.ts';
import { Creature } from '../creatures/creature.ts';
import { FireArrow, FireBall, PlantArrow, PlantBall, WaterArrow, WaterBall } from '../projectiles/projectile.ts';
import { Player } from '../player.ts';
import { BuildingKind, getBuildingElement, getBuildingTargetMode } from './building-kind.ts';
import { BuildingElement, BuildingTargetMode } from './building-types.ts';
import { BuildingStats } from './building-stats.ts';
import { paintBuildingTooltip } from '../user-interface/user-interface.ts';

export class Building implements Component {
    game: GameRoom;
    owner: Player;
    kind: BuildingKind;
    targetMode: BuildingTargetMode;
    element: BuildingElement;
    stats: BuildingStats;
    position: Point;
    fireCooldown: number = 1;

    constructor(game: GameRoom, owner: Player, kind: BuildingKind, position: Point) {
        this.game = game;
        this.owner = owner;
        this.kind = kind;
        this.targetMode = getBuildingTargetMode(kind);
        this.element = getBuildingElement(kind);
        this.stats = owner.getBuildingStats(kind);
        this.position = position;
    }

    getKind(): number {
        return this.kind;
    }

    getTile(position: Point) {
        let x = position.x;
        let y = position.y;

        return this.game.map.tiles.get(Math.floor(x / TILE_SIZE), Math.floor(y / TILE_SIZE));
    }

    update(elapsedSecs: number) {
        let TOWER_RANGE = this.stats.range * TILE_SIZE;
        let FIRE_RATE_PER_SECOND = this.stats.attacksPerSecond;

        this.fireCooldown += FIRE_RATE_PER_SECOND * elapsedSecs;
        if (this.fireCooldown >= 1) {
            for (let creature of this.game.creatureManager.creatures) {
                var distance = creature.position.getDistanceTo(this.position);
                if (distance < TOWER_RANGE) {
                    this.fireCooldown = 0;
                    this.fireProjectile(creature);
                    break;
                }
            }
        }
    }

    fireProjectile(target: Creature) {
        let mapping = {
            [BuildingTargetMode.Area + '_' + BuildingElement.Fire]: FireBall,
            [BuildingTargetMode.Single + '_' + BuildingElement.Fire]: FireArrow,
            [BuildingTargetMode.Area + '_' + BuildingElement.Water]: WaterBall,
            [BuildingTargetMode.Single + '_' + BuildingElement.Water]: WaterArrow,
            [BuildingTargetMode.Area + '_' + BuildingElement.Plant]: PlantBall,
            [BuildingTargetMode.Single + '_' + BuildingElement.Plant]: PlantArrow,
        };
        let ctor = mapping[this.targetMode + '_' + this.element];
        this.game.projectileManager.spawn(ctor, this, target, this.position.add({ x: 0, y: -TILE_SIZE / 2 }));
    }

    tooltip(view: View): void {
        paintBuildingTooltip(view, this.kind, this.stats);
    }

    render(view: View): void {
        if (this.targetMode === BuildingTargetMode.Single) {
            view.paint('sprite', {
                image: this.stats.imageUrl,
                position: this.position.toVector().add({ x: 0, y: -TILE_SIZE / 2 }),
                width: TILE_SIZE,
                height: TILE_SIZE * 2,
            });

            const color: ColorLike = {
                [BuildingElement.Fire]: Color.orangeRed(),
                [BuildingElement.Water]: Color.royalBlue(),
                [BuildingElement.Plant]: Color.green(),
            }[this.element];

            view.paint('sprite-arrow', {
                image: 'assets/buildings/arrow_8x1.png',
                imageSprite: { start: 0, end: '100%', duration: 1000, loop: 'repeat' },
                position: this.position.toVector().add({ x: 0, y: -TILE_SIZE / 2 }),
                tint: color,
                width: TILE_SIZE,
                height: TILE_SIZE * 2,
            });
        } else {
            view.paint('sprite', {
                image: this.stats.imageUrl,
                imageSprite: { start: 0, end: '100%', duration: 1000, loop: 'repeat' },
                position: this.position.toVector().add({ x: 0, y: -TILE_SIZE / 2 }),
                width: TILE_SIZE,
                height: TILE_SIZE * 2,
            });
        }
    }
}

globalThis.ALL_FUNCTIONS.push(Building);