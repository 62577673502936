import { Component, RoomApi, View, makePromise } from 'outpost';
import { GameScene } from '../scene.ts';
import { REWARD_STAT_TO_NAME, RewardStats } from '../rewards/reward-types.ts';
import { Player } from '../player.ts';
import { BuildingElement, getColoredElementName } from '../buildings/building-types.ts';
import { BOOSTER_IMAGE } from '../constants.ts';

export class RewardPanel implements Component {
    endWhen = makePromise<number>();
    slots: RewardSlot[] = [];

    constructor(player: Player) {
        this.slots = player.availableRewards!.map((reward, index) => new RewardSlot(index, reward));
    }

    async $selectReward(api: RoomApi): Promise<void> {
        let input = await api.waitForUserInput({
            selectable: this.slots,
        });

        this.endWhen.resolve(input.selection.index);
    }

    render(view: View): void {
        let rect = view.rect.scale(0.8);
        let [headerRect, contentRect] = rect.split('top', ['20%']);

        view.paint({
            sceneId: GameScene.UI,
            borderRadius: '5%',
            color: 'white',
            rect,
        });

        view.paint({
            key: 'border',
            borderRadius: '5%',
            color: 'black',
            strokeSize: '1%',
        });

        view.paint({
            key: 'header',
            rect: headerRect,
            text: 'Level up! Pick a reward:',
        });

        view.addGrid({
            items: this.slots,
            rect: contentRect,
            margin: 5,
            innerMargin: 10,
            columnSize: 1,
            itemAspectRatio: 0.5,
        });
    }

    getResolvePromise(): Promise<number> {
        return this.endWhen.promise;
    }
}

class RewardSlot implements Component {
    index: number;
    reward: RewardStats;

    constructor(index: number, reward: RewardStats) {
        this.index = index;
        this.reward = reward;
    }

    render(view: View): void {
        view.paint('background', {
            image: BOOSTER_IMAGE,
        });

        view.paint('content', {
            text: getRewardDescription(this.reward),
            textMultiline: true,
            textSize: 16,
            textPadding: 12,
        });
    }

    highlightHovered(view: View): void {
        view.paint({
            cursor: 'pointer',
            scale: { start: 1, end: 1.1, duration: 100 },
        });
    }
}

export function getRewardDescription(reward: RewardStats): string {
    let title = '';
    let lines: string[] = [];

    if (reward.kind === 'tower-craft') {
        title = `You can craft:`;

        let fireCount = reward.towersToCraft?.filter(([element]) => element === BuildingElement.Fire)?.length;
        let waterCount = reward.towersToCraft?.filter(([element]) => element === BuildingElement.Water)?.length;
        let plantCount = reward.towersToCraft?.filter(([element]) => element === BuildingElement.Plant)?.length;

        if (fireCount) {
            lines.push(`+ ${fireCount} ${getColoredElementName(BuildingElement.Fire)} towers`);
        }

        if (waterCount) {
            lines.push(`+ ${waterCount} ${getColoredElementName(BuildingElement.Water)} towers`);
        }

        if (plantCount) {
            lines.push(`+ ${plantCount} ${getColoredElementName(BuildingElement.Plant)} towers`);
        }
    } else if (reward.kind === 'tower-upgrade') {
        let element = getColoredElementName(reward.upgradedElement!);

        title = `Your ${element} towers get:`;
    } else if (reward.kind === 'spell-upgrade') {
        title = `Your teleportation spell gets:`;
    }

    for (let key in reward) {
        let content = (REWARD_STAT_TO_NAME as any)[key].replace('$', (reward as any)[key]);

        if (content) {
            lines.push(content);
        }
    }

    return [`|${title}|`, '', ...lines].join('\n');
}

globalThis.ALL_FUNCTIONS.push(RewardPanel);
globalThis.ALL_FUNCTIONS.push(RewardSlot);