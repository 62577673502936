import { Color, ColorLike } from 'outpost';
import { NEUTRAL_MULTIPLIER, STRONG_MULTIPLIER, WEAK_MULTIPLIER } from '../constants.ts';

export enum BuildingTargetMode {
    Single,
    Area,
}

export enum BuildingElement {
    Fire,
    Water,
    Plant,
}

export const BUILDING_TARGET_MODES = Object.values(BuildingTargetMode).filter(
    (value) => typeof value === 'number'
) as BuildingTargetMode[];
export const BUILDING_ELEMENTS = Object.values(BuildingElement).filter(
    (value) => typeof value === 'number'
) as BuildingElement[];

export const ELEMENT_TO_NAME: { [Key in BuildingElement]: string } = {
    [BuildingElement.Fire]: 'fire',
    [BuildingElement.Water]: 'water',
    [BuildingElement.Plant]: 'plant',
};

export const ELEMENT_TO_COLOR: { [Key in BuildingElement]: ColorLike } = {
    [BuildingElement.Fire]: 'orangered',
    [BuildingElement.Water]: 'royalblue',
    [BuildingElement.Plant]: 'green',
};

export function getDamageMultiplier(attacker: BuildingElement, defender: BuildingElement): number {
    if (attacker === defender) {
        return NEUTRAL_MULTIPLIER;
    } else if (attacker === defender + 1 || (attacker === BuildingElement.Fire && defender === BuildingElement.Plant)) {
        return STRONG_MULTIPLIER;
    } else {
        return WEAK_MULTIPLIER;
    }
}

export function getColoredElementName(element: BuildingElement): string {
    let name = ELEMENT_TO_NAME[element];
    let color = ELEMENT_TO_COLOR[element];

    if (element === BuildingElement.Water) {
        color = 'blue';
    }

    return `@${color}{${name}}`;
}
