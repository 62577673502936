import {
    SPELL_BASE_COOLDOWN_SECS,
    SPELL_BASE_CAST_DURATION_SECS,
    SPELL_BASE_RANGE,
    SPELL_BASE_SIZE,
} from '../constants.ts';

export type SpellStats = {
    size: number;
    range: number;
    castDurationSecs: number;
    cooldownSecs: number;
};

export function getDefaultSpellData(): SpellStats {
    return {
        size: SPELL_BASE_SIZE,
        range: SPELL_BASE_RANGE,
        castDurationSecs: SPELL_BASE_CAST_DURATION_SECS,
        cooldownSecs: SPELL_BASE_COOLDOWN_SECS,
    };
}

export const MIN_SPELL_COOLDOWN_SECS = 5;
