import { BuildingTargetMode, BuildingElement, BUILDING_ELEMENTS, BUILDING_TARGET_MODES } from './building-types.ts';

export type BuildingKind = number;

export function getBuildingKind(targetMode: BuildingTargetMode, element: BuildingElement): BuildingKind {
    return targetMode * BUILDING_ELEMENTS.length + element;
}

export function getBuildingKindCount(): number {
    return BUILDING_TARGET_MODES.length * BUILDING_ELEMENTS.length;
}

export function getBuildingTargetMode(kind: BuildingKind): BuildingTargetMode {
    return (kind / BUILDING_ELEMENTS.length) | 0;
}

export function getBuildingElement(kind: BuildingKind): BuildingElement {
    return kind % BUILDING_ELEMENTS.length;
}
