import { Logger, startGameHub } from 'outpost';
import { GameRoom } from './game-room.ts';
import { Player } from './player.ts';
import { AUTO_JOIN, AUTO_LOGIN } from './constants.ts';

async function main() {
    if (typeof window === 'undefined') {
        Logger.configure({
            level: 'debug',
            displayTime: true,
        });
    } else {
        Logger.configure({ level: 'info' });
    }

    startGameHub({
        gameName: 'Warp TD',
        gameConstructor: GameRoom,
        playerConstructor: Player,
        autoLogin: AUTO_LOGIN,
        autoJoin: AUTO_JOIN,
        renderer: {
            backgroundColor: 'white',
            fonts: {
                MadimiOne: 'assets/MadimiOne-Regular.ttf',
            },
            defaultFont: 'MadimiOne',
        },
    });
}

main();
