import { RoomApi, RoomClient, makeArray } from 'outpost';
import { BuildingKind, getBuildingElement, getBuildingKind, getBuildingKindCount } from './buildings/building-kind.ts';
import { GameRoom } from './game-room.ts';
import { BuildingConstructionPanel } from './buildings/building-panel.ts';
import { SpellStats, getDefaultSpellData } from './spells/spell-data.ts';
import { RewardStats } from './rewards/reward-types.ts';
import { START_BUILDING_COUNT, getBuildingStats } from './buildings/building-data.ts';
import { BuildingStats } from './buildings/building-stats.ts';
import { REWARD_COUNT } from './rewards/reward-data.ts';
import { generateReward } from './rewards/reward-generation.ts';

export type PlayerBuildingData = {
    kind: BuildingKind;
    count: number;
    stats: BuildingStats;
};

export type PlayerSpellData = {
    lastUseTime: number;
    stats: SpellStats;
};

export class Player implements RoomClient {
    id: string;
    game!: GameRoom;
    buildings: PlayerBuildingData[] = makeArray(getBuildingKindCount(), (kind) => ({
        kind,
        count: START_BUILDING_COUNT,
        stats: getBuildingStats(kind),
    }));
    spell: PlayerSpellData = {
        lastUseTime: -Infinity,
        stats: getDefaultSpellData(),
    };
    constructionPanel = new BuildingConstructionPanel(this);
    pickedRewards: RewardStats[] = [];
    availableRewards: RewardStats[] | null = null;
    connected: boolean = true;

    constructor(id: string) {
        this.id = id;
    }

    getBuildingStats(kind: BuildingKind): BuildingStats {
        return this.buildings[kind].stats;
    }

    getAvailableBuildingCount(kind: BuildingKind): number {
        return this.buildings[kind].count;
    }

    removeBuilding(kind: BuildingKind) {
        this.buildings[kind].count -= 1;
    }

    isSpellReady() {
        return this.game.currentTime >= this.spell.lastUseTime + this.spell.stats.cooldownSecs * 1000;
    }

    selectReward(index: number) {
        let reward = this.availableRewards?.[index];

        if (!reward) {
            return;
        }

        for (let [element, targetMode] of reward.towersToCraft ?? []) {
            this.buildings[getBuildingKind(targetMode, element)].count += 1;
        }

        this.spell.stats.cooldownSecs -= reward.upgradeSpellCooldown ?? 0;
        this.spell.stats.range += reward.upgradeSpellRange ?? 0;
        this.spell.stats.size += reward.upgradeSpellSize ?? 0;

        for (let { kind, stats } of this.buildings) {
            let element = getBuildingElement(kind);

            if (reward.upgradedElement === element) {
                stats.attacksPerSecond += reward.upgradeTowerAttackSpeed ?? 0;
                stats.attackDamages += reward.upgradeTowerAttackDamages ?? 0;
                stats.range += reward.upgradeTowerRange ?? 0;
                stats.spikePercentDamages += reward.upgradeTowerSpikePercentDamages ?? 0;
                stats.burnDamages += reward.upgradeTowerBurnDamages ?? 0;
                stats.slowPercent += reward.upgradeTowerSlowPercent ?? 0;
            }
        }

        this.spell.stats.cooldownSecs = Math.max(5, this.spell.stats.cooldownSecs);
        this.pickedRewards.push(reward);
        this.availableRewards = null;
    }

    generateRewards(api: RoomApi) {
        this.availableRewards = makeArray(REWARD_COUNT, (index) => generateReward(api, index));
    }
}

globalThis.ALL_FUNCTIONS.push(Player);