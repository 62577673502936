import { Component, View, clamp } from 'outpost';
import { Player } from '../player.ts';
import { paintTexts } from '../user-interface/user-interface.ts';
import { STYLES } from '../constants.ts';

export class SpellPanelSlot implements Component {
    player: Player;
    tooltipHideDelay = 5000;

    constructor(player: Player) {
        this.player = player;
    }

    tooltip(view: View): void {
        paintTexts(view, 'spell-tooltip', STYLES.layout.bottomRight, [
            {
                text: 'Teleport spell',
                size: 32,
                align: 'center',
                withDivider: true,
            },
            {
                text: 'Teleport target monsters over to target destination.',
                size: 24,
            },
            {
                text: 'Cooldown : ' + this.player.spell.stats.cooldownSecs + ' sec.',
                size: 24,
            },
            {
                text: 'Range : ' + this.player.spell.stats.range + ' tiles',
                size: 24,
            },
            {
                text: 'Target size : ' + this.player.spell.stats.size + ' tiles',
                size: 24,
            },
        ]);
    }

    render(view: View): void {
        view.paint({
            image: 'assets/tp.png',
        });

        view.paint({
            key: 'border',
            strokeSize: '3%',
            color: 'black',
        });
    }

    highlightDisabled(view: View): void {
        let spell = this.player.spell;
        let currentTime = this.player.game.currentTime / 1000;
        let availableTime = spell.lastUseTime / 1000 + spell.stats.cooldownSecs;
        let remainingCooldown = clamp(availableTime - currentTime, 0, spell.stats.cooldownSecs);
        let text = Math.ceil(remainingCooldown).toString();

        view.paint({
            key: 'overlay',
            color: 'black',
            alpha: 0.4,
            fillPercentRadial: remainingCooldown / spell.stats.cooldownSecs,
            fillPercentRadialDirection: 'counter-clockwise',
        });

        view.paint({
            key: 'overlay-text',
            text,
            textColor: 'white',
        });
    }

    highlightHovered(view: View): void {
        view.paint({
            key: 'border',
            strokeSize: { mult: 2 },
            cursor: 'pointer',
        });
    }
}

globalThis.ALL_FUNCTIONS.push(SpellPanelSlot);