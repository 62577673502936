import { Component, Constructor, Counter, Point, PointLike, RoomApi, View } from 'outpost';
import { Projectile } from './projectile.ts';
import { GameScene } from '../scene.ts';
import { GameRoom } from '../game-room.ts';
import { Creature } from '../creatures/creature.ts';
import { Building } from '../buildings/building.ts';

export class ProjectileManager implements Component {
    game: GameRoom;
    projectiles: Set<Projectile> = new Set();

    constructor(game: GameRoom) {
        this.game = game;
    }

    update(api: RoomApi, elapsedSecs: number) {
        for (let projectile of this.projectiles) {
            projectile.update(api, elapsedSecs);
        }
    }

    spawn(ctor: Constructor<Projectile, [GameRoom, Building, Creature, PointLike]>, owner: Building, target: Creature, location: Point) {
        let Projectile = new ctor(this.game, owner, target, location);
        this.projectiles.add(Projectile);
    }

    render(view: View): void {
        view.paint({ sceneId: GameScene.Overworld });
        view.addChild(this.projectiles);
    }
}

globalThis.ALL_FUNCTIONS.push(ProjectileManager);