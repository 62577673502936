import { Room, RoomApi, View } from 'outpost';
import { Player } from './player.ts';
import { GameLevel } from './game-level.ts';
import { GameMap } from './map/map.ts';
import { GameScene, WORLD_SCENES } from './scene.ts';
import { CreatureManager } from './creatures/creature-manager.ts';
import { CORE_HP, DISABLE_UPDATE, MUSIC, VIEWPORT_HEIGHT, VIEWPORT_WIDTH, WAVE_DELAY_SECS } from './constants.ts';
import { UserInterface } from './user-interface/user-interface.ts';
import { RewardPanel } from './user-interface/reward-panel.ts';
import { BuildingManager } from './buildings/building-manager.ts';
import { ProjectileManager } from './projectiles/projectile-manager.ts';
import {
    FireBatCreature,
    FireSkullCreature,
    FireSlimeCreature,
    PlantBatCreature,
    PlantSkullCreature,
    PlantSlimeCreature,
    WaterBatCreature,
    WaterSkullCreature,
    WaterSlimeCreature,
} from './creatures/creature-data.ts';
import { BuildingElement } from './buildings/building-types.ts';
import { EndOfGamePanel } from './user-interface/end-of-game-panel.ts';
import { CameraController } from './camera-controller.ts';

enum GameState {
    Playing,
    Rewards,
    Ended,
}

type ScheduledTask = {
    callback: () => void;
    delay: number;
    scheduleTime: number;
};

export class GameRoom implements Room<Player> {
    state: GameState = GameState.Playing;
    players: Player[] = [];
    level: GameLevel = new GameLevel();
    map: GameMap = new GameMap();
    creatureManager = new CreatureManager(this);
    buildingManager = new BuildingManager(this);
    projectileManager = new ProjectileManager(this);
    userInterface = new UserInterface(this);
    health: number = CORE_HP;
    spawnTimer: number = WAVE_DELAY_SECS;
    scheduledTasks: Set<ScheduledTask> = new Set();
    currentTime: number = 0;
    timeBeforeInteractionRefresh = 200;
    mustTriggerRewards: boolean = false;
    cameraController: CameraController = new CameraController();

    onCreated(api: RoomApi): void {
        api.configureServer({
            updateInterval: 20,
        });
    }

    start(api: RoomApi) {}

    onClientAdded(api: RoomApi, client: Player): void {
        this.players.push(client);
        client.game = this;
    }

    onClientRemoved(api: RoomApi, client: Player): void {
        this.players.remove(client);

        if (this.players.length === 0) {
            api.deleteRoom(GameRoom, api.roomId);
        }
    }

    onClientDisconnected(api: RoomApi, client: Player): void {
        client.connected = false;

        if (this.players.every((player) => !player.connected)) {
            api.deleteRoom(GameRoom, api.roomId);
        }
    }

    onClientReconnected(api: RoomApi, client: Player): void {
        client.connected = true;
    }

    onMount(api: RoomApi): void {
        api.configureRenderer({
            backgroundColor: '#333333',
        });
        api.initScenes<GameScene>({
            [GameScene.Tiles]: {},
            [GameScene.TilesOverlay]: {},
            [GameScene.Overworld]: {},
            [GameScene.OverworldUi]: {},
            [GameScene.UI]: {},
            [GameScene.OverworldOverlay]: {},
        });

        this.setupCamera(api);
    }

    setupCamera(api: RoomApi) {
        let cameraZoom = Math.max(VIEWPORT_WIDTH / this.map.getWidth(), VIEWPORT_HEIGHT / this.map.getHeight());
        let cameraX = this.map.getWidth() / 2;
        let cameraY = this.map.getHeight() / 2;

        api.updateScene(WORLD_SCENES, { cameraZoom, cameraX, cameraY });
    }

    updateWave(api: RoomApi, elapsedTime: number) {
        this.spawnTimer += elapsedTime;

        if (this.spawnTimer > WAVE_DELAY_SECS) {
            let randomMin = 2 + this.level.level;
            let randomMax = 4 + this.level.level;
            let count = api.getRandomNumber() * (randomMax - randomMin) + randomMin;

            count *= this.players.length;

            let element = [BuildingElement.Fire, BuildingElement.Water, BuildingElement.Plant][
                (api.getRandomNumber() * 3) | 0
            ];

            let elementCtors = {
                [BuildingElement.Fire]: {
                    skull: FireSkullCreature,
                    slime: FireSlimeCreature,
                    bat: FireBatCreature,
                },
                [BuildingElement.Water]: {
                    skull: WaterSkullCreature,
                    slime: WaterSlimeCreature,
                    bat: WaterBatCreature,
                },
                [BuildingElement.Plant]: {
                    skull: PlantSkullCreature,
                    slime: PlantSlimeCreature,
                    bat: PlantBatCreature,
                },
            };

            let ctor =
                api.getRandomNumber() < 0.5
                    ? elementCtors[element].skull
                    : api.getRandomNumber() < 0.5
                    ? elementCtors[element].slime
                    : elementCtors[element].bat;

            while (count > 0) {
                count--;
                this.creatureManager.spawn(ctor);
            }

            this.spawnTimer -= WAVE_DELAY_SECS;
        }
    }

    async $chooseReward(api: RoomApi, player: Player) {
        if (player.availableRewards === null) {
            return;
        }

        let rewardIndex = await api.prompt(new RewardPanel(player));

        await api.waitForServerResponse();

        player.selectReward(rewardIndex);
    }

    onUpdate(api: RoomApi): void {
        if (DISABLE_UPDATE) {
            return;
        }

        if (this.state === GameState.Rewards && this.players.every((player) => player.availableRewards === null)) {
            this.state = GameState.Playing;
        }

        if (this.state !== GameState.Playing) {
            return;
        }

        let elapsedTime = api.getServerTickDuration() / 1000;
        let currentTime = api.getServerCurrentTime();

        this.currentTime = currentTime;

        for (let item of this.scheduledTasks) {
            let { callback, scheduleTime, delay } = item;

            if (currentTime >= scheduleTime + delay) {
                this.scheduledTasks.delete(item);
                callback();
            }
        }

        this.creatureManager.update(api, elapsedTime);
        this.buildingManager.update(elapsedTime);
        this.projectileManager.update(api, elapsedTime);

        this.updateWave(api, elapsedTime);
        this.updateRewards(api);

        this.timeBeforeInteractionRefresh -= elapsedTime;

        api.now().render({
            components: [this.creatureManager, this.buildingManager, this.projectileManager, this.userInterface],
        });
    }

    schedule(api: RoomApi, callback: () => void, delay: number) {
        this.scheduledTasks.add({
            scheduleTime: api.getServerCurrentTime(),
            callback,
            delay,
        });
    }

    gainExp(amount: number) {
        if (this.level.gainExp(amount)) {
            this.level = this.level.getNextLevel();
            this.mustTriggerRewards = true;
        }
    }

    loseHealth() {
        this.health -= 1;
        if (this.health === 0) {
            this.state = GameState.Ended;
        }
    }

    private updateRewards(api: RoomApi) {
        if (this.mustTriggerRewards) {
            this.mustTriggerRewards = false;
            this.state = GameState.Rewards;

            for (let player of this.players) {
                player.generateRewards(api);
            }
        }
    }

    async $cheatLevelUp(api: RoomApi) {
        await api.waitForButtonPress('Shift_KeyC');
        await api.waitForServerResponse();

        this.gainExp(10000);
    }

    async $cheatLose(api: RoomApi) {
        await api.waitForButtonPress('Shift_KeyX');
        await api.waitForServerResponse();

        this.state = GameState.Ended;
    }

    async $endGame(api: RoomApi, player: Player) {
        if (this.state !== GameState.Ended) {
            return;
        }

        await api.prompt(new EndOfGamePanel());
        await api.waitForServerResponse();

        api.removeClientFromRoom(GameRoom, api.roomId, player.id);
    }

    // async $test(api: RoomApi) {
    //     await api.waitForButtonPress('Shift_KeyD');

    //     api.now().emitParticles({
    //         countPerSecond: 100,
    //         duration: Infinity,
    //         initParticle: (particle) => {
    //             particle.setColor('red', 'yellow');
    //             particle.setSize(20);
    //             particle.setStartingPosition(800, 450);
    //         },
    //     });
    // }

    render(view: View): void {
        view.addChild(this.map);
        view.addChild(this.creatureManager);
        view.addChild(this.buildingManager);
        view.addChild(this.projectileManager);
        view.addChild(this.userInterface);
        view.addChild(this.cameraController);

        view.paint({
            key: 'music',
            audio: MUSIC,
            audioLoop: true,
        });
    }
}

globalThis.ALL_FUNCTIONS.push(GameRoom);