import { BuildingElement } from '../buildings/building-types.ts';
import { TILE_SIZE } from '../constants.ts';
import { RewardStats } from './reward-types.ts';

export const REWARD_COUNT = 3;
export const TOWER_UPGRADE_PROBABILITY = 0.3;
export const SPELL_UPGRADE_PROBABILITY = 0.3;
export const MIN_TOWER_COUNT = 5;
export const MAX_TOWER_COUNT = 7;

export const FIXED_TOWER_UPGRADE_REWARDS: Omit<RewardStats, 'kind'>[] = [
    {
        upgradedElement: BuildingElement.Fire,
        upgradeTowerBurnDamages: 10,
    },
    {
        upgradedElement: BuildingElement.Water,
        upgradeTowerSlowPercent: 20,
    },
    {
        upgradedElement: BuildingElement.Plant,
        upgradeTowerSpikePercentDamages: 5,
    },
    {
        upgradeTowerAttackDamages: 10,
    },
    {
        upgradeTowerRange: 2,
    },
    {
        upgradeTowerAttackSpeed: 0.5,
    },
];

export const FIXED_SPELL_UPGRADE_REWARDS: Omit<RewardStats, 'kind'>[] = [
    {
        upgradeSpellSize: 2,
    },
    {
        upgradeSpellRange: 10,
    },
    {
        upgradeSpellCooldown: 5,
    },
];
