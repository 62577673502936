export default {};
export const Route = {};
export const Router = {};
export const application = {};
export const json = {};
export const query = {};
export const raw = {};
export const request = {};
export const response = {};
const staticDummy = {};
export { staticDummy as static };
export const text = {};
export const urlencoded = {};