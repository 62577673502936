import { Button, Component, Graphics, Grid, Point, PointLike, Rect, Vector, View, getRandomItem } from 'outpost';
import { TILE_TO_COLOR, Tile, parseMapTiles } from './map-tiles.ts';
import { GameScene } from '../scene.ts';
import { DEBUG_TILES, MAP, TILE_SIZE } from '../constants.ts';
import { getTileCenter } from './map-utils.ts';

export type TileData = {
    gridX: number;
    gridY: number;
    tile: Tile | null;
};

export class GameMap implements Component {
    tiles: Grid<Tile>;
    spawnLocations: Point[] = [];

    constructor() {
        this.tiles = parseMapTiles(MAP);
        this.collectSpawnLocations();
    }

    getWidth(): number {
        return this.tiles.width * TILE_SIZE;
    }

    getHeight(): number {
        return this.tiles.height * TILE_SIZE;
    }

    getTileAt(x: number, y: number): TileData {
        let gridX = Math.floor(x / TILE_SIZE);
        let gridY = Math.floor(y / TILE_SIZE);
        let tile = this.tiles.get(gridX, gridY) ?? null;

        return { gridX, gridY, tile };
    }

    render(view: View): void {
        let firstCoreCell: PointLike | undefined = undefined;

        for (let { x, y, value } of this.tiles.cells()) {
            if (value === Tile.Core) {
                firstCoreCell = { x, y };
            }
            let tileGraphics: Graphics = {
                key: `tile-${x}-${y}`,
                // angle: 2 * Math.PI,
                sceneId: GameScene.Tiles,
                detectable: false,
                rect: {
                    x1: x * TILE_SIZE,
                    y1: y * TILE_SIZE,
                    width: TILE_SIZE,
                    height: TILE_SIZE,
                },
            };

            if (DEBUG_TILES) {
                view.paint({
                    ...tileGraphics,
                    color: TILE_TO_COLOR[value] ?? 'black',
                });
            } else {
                let imageSprite = value === Tile.None ? 4 : value === Tile.Empty ? 2 : 0;
                if (x % 2 == 0) {
                    imageSprite = y % 2 == 0 ? imageSprite : imageSprite + 1;
                } else {
                    imageSprite = y % 2 == 0 ? imageSprite + 1 : imageSprite;
                }

                view.paint({
                    ...tileGraphics,
                    image: 'assets/tilemap_2x3.png',
                    imageSprite,
                });
            }
        }

        if (!firstCoreCell || DEBUG_TILES) {
            return;
        }

        view.paint({
            key: 'core-village',
            sceneId: GameScene.Tiles,
            image: 'assets/village.png',
            position: Point.from(firstCoreCell)
                .mult(TILE_SIZE)
                .sub(Vector.from({ x: TILE_SIZE, y: TILE_SIZE })),
            width: TILE_SIZE * 4,
            height: TILE_SIZE * 4,
        });
    }

    private collectSpawnLocations() {
        for (let { x, y, value } of this.tiles.cells()) {
            if (value === Tile.RoadSpawn) {
                this.spawnLocations.push(getTileCenter(x, y));
            }
        }
    }

    getSpawnLocationCount(): number {
        return this.spawnLocations.length;
    }

    getSpawnLocation(index: number): Point {
        return this.spawnLocations[index % this.spawnLocations.length];
    }
}

globalThis.ALL_FUNCTIONS.push(GameMap);