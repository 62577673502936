import { ColorLike, Component, Point, View } from 'outpost';
import { CANON_AREA } from '../buildings/building-data.ts';
import { TILE_SIZE } from '../constants.ts';
import { GameScene } from '../scene.ts';

export class ProjectileExplosion implements Component {
    position: Point;
    color: ColorLike;

    constructor(position: Point, color: ColorLike) {
        this.position = position;
        this.color = color;
    }

    render(view: View): void {
        view.paint({
            sceneId: GameScene.TilesOverlay,
            duration: 350,
            position: this.position,
            width: CANON_AREA * TILE_SIZE,
            height: CANON_AREA * TILE_SIZE,
            scale: { start: 1, end: 0.9 },
            color: this.color,
            alpha: { start: 0.5, end: 0.2 },
            borderRadius: '50%',
        });
    }
}

globalThis.ALL_FUNCTIONS.push(ProjectileExplosion);