export enum GameScene {
    Tiles = 'tiles',
    TilesOverlay = 'tiles-overlay',
    Overworld = 'overworld',
    OverworldUi = 'overworld-ui',
    UI = 'user-interface',
    OverworldOverlay = 'overworld-overlay',
}

export const WORLD_SCENES = [
    GameScene.Tiles,
    GameScene.TilesOverlay,
    GameScene.Overworld,
    GameScene.OverworldUi,
    GameScene.OverworldOverlay,
];
