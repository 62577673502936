import { Rect } from 'outpost';
import BIG_MAP from './../assets/map.png';
import SMALL_MAP from './../assets/map2.png';
export { default as BOOSTER_IMAGE } from './../assets/booster.png';
export { default as MUSIC } from './../assets/music.mp3';

export const USE_SMALL_MAP = false;
export const DEBUG_TILES = false;
export const DISABLE_UPDATE = false;
export const AUTO_JOIN = false;
export const AUTO_LOGIN = true;

export const MAP = USE_SMALL_MAP ? SMALL_MAP : BIG_MAP;
export const VIEWPORT_WIDTH = 1600;
export const VIEWPORT_HEIGHT = 900;
export const TILE_SIZE = 10;
export const CAMERA_PAN_SPEED = 500;
export const CAMERA_ZOOM_SPEED = 1.0025;
export const CAMERA_ZOOM_DURATION = 200;
export const CORE_HP = 30;
export const WAVE_DELAY_SECS = 10;

export const ARROW_TOWER_ATTACK_PER_SECOND = 1;
export const CANON_TOWER_ATTACK_PER_SECOND = 0.5;

export const STRONG_MULTIPLIER = 2.5;
export const NEUTRAL_MULTIPLIER = 1;
export const WEAK_MULTIPLIER = 0.2;

export const SPELL_BASE_SIZE = 2;
export const SPELL_BASE_RANGE = 10;
export const SPELL_BASE_CAST_DURATION_SECS = 1;
export const SPELL_BASE_COOLDOWN_SECS = 20;
// export const SPELL_BASE_COOLDOWN_SECS = 1;

const VIEWPORT_RECT = Rect.fromCorners(0, 0, VIEWPORT_WIDTH, VIEWPORT_HEIGHT);
export const STYLES = {
    borderColor: '#5a658b',
    backgroundColor: '#3b425b',
    textColor: 'snow',

    layout: {
        screen: VIEWPORT_RECT,
        bottom: VIEWPORT_RECT.fromBottomInwards(800, 180),
        bottomLeft: VIEWPORT_RECT.fromBottomLeftInwards(400, 180),
        bottomRight: VIEWPORT_RECT.fromBottomRightInwards(400, 180),
    },
} as const;
