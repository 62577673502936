import { Component, RoomApi, View, makeArray } from 'outpost';
import { Player } from '../player.ts';
import { BuildingIndicator, isConstructibleComponent } from './building-indicator.ts';
import { Tile } from '../map/map-tiles.ts';
import { getBuildingKindCount, BuildingKind, getBuildingTargetMode, getBuildingElement } from './building-kind.ts';
import { getBuildingStats } from './building-data.ts';
import { TILE_SIZE, STYLES } from '../constants.ts';
import { paintBuildingTooltip } from '../user-interface/user-interface.ts';
import { BuildingTargetMode, ELEMENT_TO_COLOR } from './building-types.ts';
import { SpellPanelSlot } from '../spells/spell-panel.ts';
import { SpellIndicator } from '../spells/spell-indicator.ts';

export class BuildingConstructionPanel implements Component {
    player: Player;
    spellSlot: SpellPanelSlot;
    buildingSlots: BuildingConstructionSlot[];

    constructor(player: Player) {
        this.player = player;
        this.buildingSlots = makeArray(getBuildingKindCount(), (kind) => new BuildingConstructionSlot(player, kind));
        this.spellSlot = new SpellPanelSlot(player);
    }

    render(view: View): void {
        let rect = STYLES.layout.bottom.translate(0, 32);

        view.addGrid({
            items: [this.spellSlot, ...this.buildingSlots],
            rect: rect.translate(0, -10).withHeight(140),
            margin: 20,
            columnSize: 1,
            itemAspectRatio: 1,
        });
    }

    async $useSpell(api: RoomApi, player: Player) {
        await api.waitForUserInput({
            shortcuts: {
                KeyQ: this.spellSlot,
                Digit1: this.spellSlot,
            },
            selectable: this.spellSlot,
            isEnabled: () => player.isSpellReady(),
        });

        let indicator = new SpellIndicator(player.spell.stats);
        let castInfo = await api.prompt(indicator);

        if (!castInfo) {
            return;
        }

        let [source, target] = castInfo;

        if (source.getDistanceTo(target) > player.spell.stats.range * TILE_SIZE) {
            return;
        }

        api.render(indicator);

        await api.waitForServerResponse();

        this.player.spell.lastUseTime = api.getServerCurrentTime();
        this.player.game.schedule(
            api,
            () => {
                this.player.game.creatureManager.teleport(source, target, player.spell.stats.size * TILE_SIZE);
            },
            player.spell.stats.castDurationSecs * 1000
        );
    }

    async $build(api: RoomApi, player: Player) {
        let input = await api.waitForUserInput({
            selectable: this.buildingSlots,
            shortcuts: {
                Digit2: this.buildingSlots[0],
                Digit3: this.buildingSlots[1],
                Digit4: this.buildingSlots[2],
                Digit5: this.buildingSlots[3],
                Digit6: this.buildingSlots[4],
                Digit7: this.buildingSlots[5],
            },
            isEnabled: (slot) => slot.getCount() > 0,
            selectionTrigger: ['drag', 'up'],
        });

        let item = input.selection;
        let position = await api.prompt(new BuildingIndicator(this.player.game, item.kind));

        if (!position) {
            return;
        }

        let { gridX, gridY, tile } = this.player.game.map.getTileAt(position.x, position.y);
        let building = this.player.game.buildingManager.getBuildingAt(gridX, gridY);

        if (
            tile !== Tile.Empty ||
            building ||
            api.getClientData(() => !isConstructibleComponent(api.getHoveredComponent()))
        ) {
            return;
        }

        await api.waitForServerResponse();

        this.player.game.buildingManager.spawn(player, item.kind, gridX, gridY);
        this.player.removeBuilding(item.kind);
    }
}

export class BuildingConstructionSlot implements Component {
    player: Player;
    kind: BuildingKind;
    tooltipHideDelay = 5000;

    constructor(player: Player, kind: BuildingKind) {
        this.player = player;
        this.kind = kind;
    }

    getCount(): number {
        return this.player.getAvailableBuildingCount(this.kind);
    }

    tooltip(view: View): void {
        paintBuildingTooltip(view, this.kind, this.player.getBuildingStats(this.kind));
    }

    render(view: View): void {
        let count = this.getCount();

        view.paint({
            image: getBuildingStats(this.kind).imageUrl,
            imageSprite: 0,
        });

        if (getBuildingTargetMode(this.kind) === BuildingTargetMode.Single) {
            view.paint({
                key: 'arrow',
                image: 'assets/buildings/arrow_8x1.png',
                tint: ELEMENT_TO_COLOR[getBuildingElement(this.kind)],
                imageSprite: 0,
            });
        }

        view.paint({
            key: 'count',
            rect: view.rect.fromBottomInwards('100%', '50%'),
            text: count.toString(),
            textSize: 34,
            textColor: 'white',
        });

        view.paint({
            key: 'border',
            strokeSize: '3%',
            borderRadius: 8,
            color: 'black',
        });
    }

    highlightDisabled(view: View): void {
        view.paint({
            key: 'overlay',
            color: 'black',
            alpha: 0.3,
        });
    }

    highlightHovered(view: View): void {
        view.paint({
            key: 'border',
            strokeSize: { mult: 2 },
            cursor: 'pointer',
        });
    }
}

globalThis.ALL_FUNCTIONS.push(BuildingConstructionPanel);
globalThis.ALL_FUNCTIONS.push(BuildingConstructionSlot);