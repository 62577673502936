import { makeArray } from 'outpost';
import { getBuildingElement, getBuildingKindCount, getBuildingTargetMode } from './building-kind.ts';
import { BuildingStats, BuildingStatsByElement, BuildingStatsByTargetMode } from './building-stats.ts';
import { BuildingTargetMode, BuildingElement } from './building-types.ts';
import FIRE_AREA from '../../assets/buildings/area-fire_5x1.png';
import WATER_AREA from '../../assets/buildings/area-water_6x1.png';
import PLANT_AREA from '../../assets/buildings/area-plant_5x1.png';

export const START_BUILDING_COUNT = 1;
export const CANON_AREA = 4;

const ARROW_TOWER_STATS: BuildingStatsByTargetMode = {
    attackDamages: 20,
    attacksPerSecond: 1,
    range: 4,
    areaDiameter: 0,
    projectileSize: 0.25,
    projectileSpeed: 15,
};

const CANON_TOWER_STATS: BuildingStatsByTargetMode = {
    attackDamages: 10,
    attacksPerSecond: 0.2,
    range: 4,
    areaDiameter: CANON_AREA,
    projectileSize: 0.8,
    projectileSpeed: 6,
};

const FIRE_TOWER_STATS: BuildingStatsByElement = {
    duration: 5,
    burnDamages: 10,
    slowPercent: 0,
    spikePercentDamages: 0,
};

const WATER_TOWER_STATS: BuildingStatsByElement = {
    duration: 8,
    burnDamages: 0,
    slowPercent: 30,
    spikePercentDamages: 0,
};

const PLANT_TOWER_STATS: BuildingStatsByElement = {
    duration: 0,
    burnDamages: 0,
    slowPercent: 0,
    spikePercentDamages: 10,
};

export const BUILDING_DATA: { [Key in BuildingElement]: { [Key in BuildingTargetMode]: BuildingStats } } = {
    [BuildingElement.Fire]: {
        [BuildingTargetMode.Single]: {
            imageUrl: 'assets/buildings/arrow-tower.png',
            ...ARROW_TOWER_STATS,
            ...FIRE_TOWER_STATS,
        },
        [BuildingTargetMode.Area]: {
            imageUrl: FIRE_AREA,
            ...CANON_TOWER_STATS,
            ...FIRE_TOWER_STATS,
        },
    },
    [BuildingElement.Water]: {
        [BuildingTargetMode.Single]: {
            imageUrl: 'assets/buildings/arrow-tower.png',
            ...ARROW_TOWER_STATS,
            ...WATER_TOWER_STATS,
        },
        [BuildingTargetMode.Area]: {
            imageUrl: WATER_AREA,
            ...CANON_TOWER_STATS,
            ...WATER_TOWER_STATS,
        },
    },
    [BuildingElement.Plant]: {
        [BuildingTargetMode.Single]: {
            imageUrl: 'assets/buildings/arrow-tower.png',
            ...ARROW_TOWER_STATS,
            ...PLANT_TOWER_STATS,
        },
        [BuildingTargetMode.Area]: {
            imageUrl: PLANT_AREA,
            ...CANON_TOWER_STATS,
            ...PLANT_TOWER_STATS,
        },
    },
};

const BASE_BUILDING_STATS: BuildingStats[] = makeArray(getBuildingKindCount(), (kind) => {
    let element = getBuildingElement(kind);
    let targetMode = getBuildingTargetMode(kind);

    return BUILDING_DATA[element][targetMode];
});

export function getBuildingStats(kind: number): BuildingStats {
    let stats = BASE_BUILDING_STATS[kind];

    if (!stats) {
        throw new Error(`no stats found for kind ${kind}`);
    }

    return stats;
}
