import { BuildingElement, BuildingTargetMode } from '../buildings/building-types.ts';

export type RewardKind = 'tower-craft' | 'tower-upgrade' | 'spell-upgrade';

export type RewardStats = {
    kind: RewardKind;
    towersToCraftElements?: BuildingElement[];
    towersToCraft?: [BuildingElement, BuildingTargetMode][];
    upgradedElement?: BuildingElement | null;
    upgradeTowerAttackSpeed?: number;
    upgradeTowerAttackDamages?: number;
    upgradeTowerRange?: number;
    upgradeTowerBurnDamages?: number;
    upgradeTowerSpikePercentDamages?: number;
    upgradeTowerSlowPercent?: number;
    upgradeSpellRange?: number;
    upgradeSpellCooldown?: number;
    upgradeSpellSize?: number;
};

export const REWARD_STAT_TO_NAME: { [Key in keyof RewardStats]: string } = {
    kind: '',
    towersToCraftElements: '',
    towersToCraft: '',
    upgradedElement: '',
    upgradeTowerAttackSpeed: '+ $ attack speed',
    upgradeTowerAttackDamages: '+ $ attack damages',
    upgradeTowerRange: '+ $ attack range',
    upgradeTowerBurnDamages: '+ $ burn damages',
    upgradeTowerSpikePercentDamages: '+ $% max health spike damages',
    upgradeTowerSlowPercent: '+ $% slow',
    upgradeSpellRange: '+ $ range',
    upgradeSpellCooldown: '- $ secs cooldown',
    upgradeSpellSize: '+ $ diameter',
};
