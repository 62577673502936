import { Component, Point, RoomApi, View, makePromise } from 'outpost';
import { GameScene } from '../scene.ts';
import { SpellStats } from './spell-data.ts';
import { TILE_SIZE } from '../constants.ts';

export class SpellIndicator implements Component {
    spellStats: SpellStats;
    sourcePosition: Point | null = null;
    targetPosition: Point | null = null;
    showIndicator: boolean = true;
    onComplete = makePromise<[Point, Point] | null>();

    constructor(spellStats: SpellStats) {
        this.spellStats = spellStats;
    }

    getResolvePromise(): Promise<[Point, Point] | null> {
        return this.onComplete.promise;
    }

    onNewFrame(api: RoomApi): void {
        if (this.showIndicator) {
            api.render(this);
        }
    }

    async $interaction(api: RoomApi) {
        let source = await api.waitForUserInput({
            shortcuts: { MouseLeft: true, Escape: false },
            sceneId: GameScene.OverworldOverlay,
        });

        if (!source.selection) {
            this.onComplete.resolve(null);
            return;
        }

        this.sourcePosition = source.position;

        api.render(this);

        let target = await api.waitForUserInput({
            shortcuts: { MouseLeft: true, Escape: false },
            sceneId: GameScene.OverworldOverlay,
        });

        if (!source.selection) {
            this.onComplete.resolve(null);
            return;
        }

        this.targetPosition = target.position;
        this.showIndicator = false;

        this.onComplete.resolve([this.sourcePosition, this.targetPosition]);
    }

    render(view: View): void {
        let pointerPosition = view.getPointerPosition(GameScene.OverworldOverlay);
        let castDuration = this.spellStats.castDurationSecs * 1000;
        let spellSize = this.spellStats.size * TILE_SIZE;
        let spellRange = this.spellStats.range * TILE_SIZE;

        if (!this.showIndicator) {
            view.setLifetime(castDuration);
        }

        if (this.sourcePosition) {
            if (!this.targetPosition) {
                view.paint({
                    key: 'range',
                    sceneId: GameScene.OverworldOverlay,
                    color: 'green',
                    position: this.sourcePosition,
                    width: spellRange * 2,
                    height: spellRange * 2,
                    borderRadius: '50%',
                    strokeSize: 1,
                });
            }

            view.paint({
                key: 'source',
                sceneId: GameScene.OverworldOverlay,
                color: 'cyan',
                position: this.sourcePosition,
                width: spellSize,
                height: spellSize,
                borderRadius: '50%',
                strokeSize: '3%',
            });
        }

        if (this.targetPosition) {
            view.paint({
                key: 'target',
                sceneId: GameScene.OverworldOverlay,
                color: 'cyan',
                position: this.targetPosition,
                width: spellSize,
                height: spellSize,
                borderRadius: '50%',
                strokeSize: '3%',
            });
        }

        if (this.sourcePosition && this.targetPosition) {
            view.paint({
                key: 'source-overlay',
                sceneId: GameScene.OverworldOverlay,
                color: 'blue',
                position: this.sourcePosition,
                width: spellSize,
                height: spellSize,
                borderRadius: '50%',
                strokeSize: '3%',
                fillPercentRadial: { start: 0, end: 1, duration: castDuration },
            });

            view.paint({
                key: 'target-overlay',
                sceneId: GameScene.OverworldOverlay,
                color: 'red',
                position: this.targetPosition,
                width: spellSize,
                height: spellSize,
                borderRadius: '50%',
                strokeSize: '3%',
                fillPercentRadial: { start: 0, end: 1, duration: castDuration },
            });
        }

        if (this.showIndicator) {
            view.paint({
                key: 'indicator',
                sceneId: GameScene.OverworldOverlay,
                color: 'cyan',
                alpha: 0.5,
                position: pointerPosition,
                width: spellSize,
                height: spellSize,
                borderRadius: '50%',
            });
        }
    }
}

globalThis.ALL_FUNCTIONS.push(SpellIndicator);