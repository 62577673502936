import { Component, Point, RoomApi, View, makePromise } from 'outpost';
import { GameRoom } from '../game-room.ts';
import { GameScene } from '../scene.ts';
import { TILE_SIZE } from '../constants.ts';
import { Tile } from '../map/map-tiles.ts';
import { getTileCenter } from '../map/map-utils.ts';
import { BuildingKind } from './building-kind.ts';
import { getBuildingStats } from './building-data.ts';
import { Building } from './building.ts';

export class BuildingIndicator implements Component {
    game: GameRoom;
    kind: BuildingKind;
    onComplete = makePromise<Point | null>();

    constructor(game: GameRoom, kind: BuildingKind) {
        this.game = game;
        this.kind = kind;
    }

    onNewFrame(api: RoomApi): void {
        api.render(this);
    }

    async $run(api: RoomApi) {
        let input = await api.waitForUserInput({
            shortcuts: {
                MouseLeft: true,
                Escape: false,
            },
            shortcutTrigger: ['down', 'up'],
            sceneId: GameScene.Overworld,
        });

        if (!input.selection) {
            this.onComplete.resolve(null);
        } else {
            this.onComplete.resolve(input.position);
        }
    }

    render(view: View): void {
        let pointerPosition = view.getPointerPosition(GameScene.Overworld);
        let { gridX, gridY, tile } = this.game.map.getTileAt(pointerPosition.x, pointerPosition.y);
        let building = this.game.buildingManager.getBuildingAt(gridX, gridY);

        view.paint({ detectable: false });

        if (tile === Tile.Empty && !building && isConstructibleComponent(view.getHoveredComponent())) {
            view.paint({
                key: 'overlay',
                sceneId: GameScene.TilesOverlay,
                color: 'red',
                position: getTileCenter(gridX, gridY),
                width: TILE_SIZE,
                height: TILE_SIZE,
            });
        }

        view.paint({
            key: 'icon',
            sceneId: GameScene.OverworldOverlay,
            image: getBuildingStats(this.kind).imageUrl,
            width: TILE_SIZE,
            height: TILE_SIZE * 2,
            position: pointerPosition.add([0, -TILE_SIZE]),
            alpha: 0.8,
            cursor: 'grabbing',
        });
    }

    getResolvePromise(): Promise<Point | null> {
        return this.onComplete.promise;
    }
}

export function isConstructibleComponent(component: Component | null): boolean {
    return !component || component instanceof Building;
}

globalThis.ALL_FUNCTIONS.push(BuildingIndicator);