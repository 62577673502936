export default {};
export const Agent = {};
export const ClientRequest = {};
export const IncomingMessage = {};
export const METHODS = {};
export const OutgoingMessage = {};
export const STATUS_CODES = {};
export const Server = {};
export const ServerResponse = {};
export const _connectionListener = {};
export const createServer = {};
export const get = {};
export const globalAgent = {};
export const maxHeaderSize = {};
export const request = {};
export const setMaxIdleHTTPParsers = {};
export const validateHeaderName = {};
export const validateHeaderValue = {};