export default {};
export const Dir = {};
export const Dirent = {};
export const F_OK = {};
export const FileReadStream = {};
export const FileWriteStream = {};
export const R_OK = {};
export const ReadStream = {};
export const Stats = {};
export const W_OK = {};
export const WriteStream = {};
export const X_OK = {};
export const _toUnixTimestamp = {};
export const access = {};
export const accessSync = {};
export const appendFile = {};
export const appendFileSync = {};
export const chmod = {};
export const chmodSync = {};
export const chown = {};
export const chownSync = {};
export const close = {};
export const closeSync = {};
export const constants = {};
export const copyFile = {};
export const copyFileSync = {};
export const cp = {};
export const cpSync = {};
export const createReadStream = {};
export const createWriteStream = {};
export const exists = {};
export const existsSync = {};
export const fchmod = {};
export const fchmodSync = {};
export const fchown = {};
export const fchownSync = {};
export const fdatasync = {};
export const fdatasyncSync = {};
export const fstat = {};
export const fstatSync = {};
export const fsync = {};
export const fsyncSync = {};
export const ftruncate = {};
export const ftruncateSync = {};
export const futimes = {};
export const futimesSync = {};
export const lchmod = {};
export const lchmodSync = {};
export const lchown = {};
export const lchownSync = {};
export const link = {};
export const linkSync = {};
export const lstat = {};
export const lstatSync = {};
export const lutimes = {};
export const lutimesSync = {};
export const mkdir = {};
export const mkdirSync = {};
export const mkdtemp = {};
export const mkdtempSync = {};
export const open = {};
export const openAsBlob = {};
export const openSync = {};
export const opendir = {};
export const opendirSync = {};
export const promises = {};
export const read = {};
export const readFile = {};
export const readFileSync = {};
export const readSync = {};
export const readdir = {};
export const readdirSync = {};
export const readlink = {};
export const readlinkSync = {};
export const readv = {};
export const readvSync = {};
export const realpath = {};
export const realpathSync = {};
export const rename = {};
export const renameSync = {};
export const rm = {};
export const rmSync = {};
export const rmdir = {};
export const rmdirSync = {};
export const stat = {};
export const statSync = {};
export const statfs = {};
export const statfsSync = {};
export const symlink = {};
export const symlinkSync = {};
export const truncate = {};
export const truncateSync = {};
export const unlink = {};
export const unlinkSync = {};
export const unwatchFile = {};
export const utimes = {};
export const utimesSync = {};
export const watch = {};
export const watchFile = {};
export const write = {};
export const writeFile = {};
export const writeFileSync = {};
export const writeSync = {};
export const writev = {};
export const writevSync = {};