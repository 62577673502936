import { ColorLike, Grid, PixelGrid, Vector, mapObject } from 'outpost';

export enum Tile {
    None,
    Empty,
    Core,
    Road,
    RoadSpawn,
    RoadTurnLeft,
    RoadTurnRight,
    RoadTurnTop,
    RoadTurnBottom,
}

export const COLOR_TO_TILE: { [key: string]: Tile } = {
    '#000000': Tile.None,
    '#FFFFFF': Tile.Empty,
    '#FF00FF': Tile.Core,
    '#008000': Tile.Road,
    '#808080': Tile.RoadSpawn,
    '#0000FF': Tile.RoadTurnLeft,
    '#FF0000': Tile.RoadTurnRight,
    '#FFFF00': Tile.RoadTurnTop,
    '#00FFFF': Tile.RoadTurnBottom,
};

export const TILE_TO_MOVEMENT: Partial<{ [Key in Tile]: Vector }> = {
    [Tile.RoadTurnLeft]: Vector.left(),
    [Tile.RoadTurnRight]: Vector.right(),
    [Tile.RoadTurnTop]: Vector.top(),
    [Tile.RoadTurnBottom]: Vector.bottom(),
};

export const TILE_TO_COLOR: { [Key in Tile]: ColorLike } = mapObject(COLOR_TO_TILE, (key, value) => [value, key]);

export function parseMapTiles(filePath: string): Grid<Tile> {
    let pixelGrid = PixelGrid.loadFromFile(filePath);
    let tiles = new Grid<Tile>({
        width: pixelGrid.width,
        height: pixelGrid.height,
        makeItem: () => Tile.None,
    });

    for (let x = 0; x < pixelGrid.width; ++x) {
        for (let y = 0; y < pixelGrid.height; ++y) {
            let color = pixelGrid.get(x, y).toHexString();
            let tile = COLOR_TO_TILE[color] ?? Tile.None;

            tiles.set(x, y, tile);
        }
    }

    for (let { x, y, value } of tiles.cells()) {
        if (value === Tile.RoadSpawn) {
            let startTile = getSpawnTile(tiles, x, y);
            replaceTile(tiles, x, y, startTile);
        }
    }

    return tiles;
}

export function getSpawnTile(grid: Grid<Tile>, xGrid: number, yGrid: number): Tile {
    let dLeft = xGrid / grid.width;
    let dRight = 1 - dLeft;
    let dTop = yGrid / grid.height;
    let dBottom = 1 - dTop;
    let min = Math.min(dLeft, dRight, dTop, dBottom);

    if (min === dLeft) {
        return Tile.RoadTurnRight;
    } else if (min === dRight) {
        return Tile.RoadTurnLeft;
    } else if (min === dTop) {
        return Tile.RoadTurnBottom;
    } else {
        return Tile.RoadTurnTop;
    }
}

function replaceTile(grid: Grid<Tile>, xGrid: number, yGrid: number, source: Tile) {
    let tile = grid.get(xGrid, yGrid);

    if (tile === Tile.Road) {
        grid.set(xGrid, yGrid, source);
        tile = source;
    }

    if (tile === Tile.RoadSpawn) {
        tile = source;
    }

    let direction = tile && TILE_TO_MOVEMENT[tile];

    if (tile && direction) {
        replaceTile(grid, xGrid + direction.x, yGrid + direction.y, tile);
    }
}
