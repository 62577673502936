export default {};
export const _makeLong = {};
export const basename = {};
export const delimiter = {};
export const dirname = {};
export const extname = {};
export const format = {};
export const isAbsolute = {};
export const join = {};
export const normalize = {};
export const parse = {};
export const posix = {};
export const relative = {};
export const resolve = {};
export const sep = {};
export const toNamespacedPath = {};
export const win32 = {};