export default {};
export const bgBlack = {};
export const bgBlackBright = {};
export const bgBlue = {};
export const bgBlueBright = {};
export const bgCyan = {};
export const bgCyanBright = {};
export const bgGreen = {};
export const bgGreenBright = {};
export const bgMagenta = {};
export const bgMagentaBright = {};
export const bgRed = {};
export const bgRedBright = {};
export const bgWhite = {};
export const bgWhiteBright = {};
export const bgYellow = {};
export const bgYellowBright = {};
export const black = {};
export const blackBright = {};
export const blue = {};
export const blueBright = {};
export const bold = {};
export const createColors = {};
export const cyan = {};
export const cyanBright = {};
export const dim = {};
export const gray = {};
export const green = {};
export const greenBright = {};
export const hidden = {};
export const inverse = {};
export const isColorSupported = {};
export const italic = {};
export const magenta = {};
export const magentaBright = {};
export const red = {};
export const redBright = {};
export const reset = {};
export const strikethrough = {};
export const underline = {};
export const white = {};
export const whiteBright = {};
export const yellow = {};
export const yellowBright = {};