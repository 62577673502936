import { Component, Point, RoomApi, View, applyEasing, clamp, mix } from 'outpost';
import { CAMERA_PAN_SPEED, CAMERA_ZOOM_DURATION, CAMERA_ZOOM_SPEED } from './constants.ts';
import { GameScene, WORLD_SCENES } from './scene.ts';
import { SceneProperties } from '../../outpost/src/framework/graphics-engine/scene-types.ts';

export class CameraController implements Component {
    private dx: number = 0;
    private dy: number = 0;
    private dragStart: Point | null = null;
    private currentCamera!: SceneProperties;
    private targetZoom: number | null = null;
    private elapsedZoomTime: number = 0;

    async $moveCamera(api: RoomApi) {
        let input = await api.waitForUserInput({
            shortcuts: {
                KeyA: { dx: -1, dy: 0 },
                KeyD: { dx: 1, dy: 0 },
                KeyW: { dx: 0, dy: -1 },
                KeyS: { dx: 0, dy: 1 },
            },
            shortcutTrigger: ['down', 'up'],
        });

        let { dx, dy } = input.selection;
        let isUp = input.trigger === 'up';
        let m = isUp ? -1 : 1;

        this.dx = clamp(this.dx + dx * m, -1, 1);
        this.dy = clamp(this.dy + dy * m, -1, 1);
    }

    async $dragCamera(api: RoomApi) {
        if (this.targetZoom) {
            return;
        }

        let input = await api.waitForButtonPress('MouseRight');

        this.currentCamera = api.getSceneProperties(GameScene.Tiles);
        this.dragStart = input.position;

        await api.waitForButtonRelease('MouseRight');

        this.dragStart = null;
        this.elapsedZoomTime = 0;
    }

    async $zoomCamera(api: RoomApi) {
        let input = await api.waitForScroll();

        this.dragStart = null;
        this.currentCamera = api.getSceneProperties(GameScene.Tiles);

        let op = input.scrollDeltaY < 0 ? (x: number) => x : (x: number) => 1 / x;
        let currentZoom = this.targetZoom ? this.targetZoom : this.currentCamera.cameraZoom;
        let nextZoom = currentZoom * op(CAMERA_ZOOM_SPEED ** Math.abs(input.scrollDeltaY));

        this.targetZoom = nextZoom;
        this.elapsedZoomTime = 0;
    }

    async $microZoom(api: RoomApi) {
        let input = await api.waitForButtonPress(['Enter', 'Shift_Enter']);
        let zoom = api.getSceneProperties(GameScene.Tiles).cameraZoom;
        let ratio = 1.01 ** 2;
        let m = input.shiftKey ? 1 / ratio : ratio;

        api.updateScene(WORLD_SCENES, { cameraZoom: zoom * m });
    }

    onNewFrame(api: RoomApi): void {
        if (this.targetZoom) {
            let elapsed = api.getFrameDuration();

            this.elapsedZoomTime += elapsed;

            let t = applyEasing('quadratic-out', Math.min(1, this.elapsedZoomTime / CAMERA_ZOOM_DURATION));
            let cameraZoom = mix(this.currentCamera.cameraZoom, this.targetZoom, t);

            api.updateScene(WORLD_SCENES, { cameraZoom });

            if (this.elapsedZoomTime >= CAMERA_ZOOM_DURATION) {
                this.targetZoom = null;
            }
        } else if (this.dragStart) {
            let dragCurrent = api.getPointerPosition();
            let dragOffset = this.dragStart.getVectorTo(dragCurrent).div(this.currentCamera.cameraZoom);

            api.updateScene(WORLD_SCENES, {
                cameraX: this.currentCamera.cameraX - dragOffset.x,
                cameraY: this.currentCamera.cameraY - dragOffset.y,
            });
        } else if (this.dx !== 0 || this.dy !== 0) {
            let current = api.getSceneProperties(GameScene.Tiles);
            let elapsed = api.getFrameDuration() / 1000;
            let tx = this.dx * elapsed * CAMERA_PAN_SPEED;
            let ty = this.dy * elapsed * CAMERA_PAN_SPEED;

            api.updateScene(WORLD_SCENES, {
                cameraX: current.cameraX + tx,
                cameraY: current.cameraY + ty,
            });
        }
    }

    render(view: View): void {}
}

globalThis.ALL_FUNCTIONS.push(CameraController);